@import "variables.css";

html,
#root {
  height: 100%;
  overflow: hidden;
}
body {
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100%;
  background-color: var(--page-bg-1);
}

.center {
  display: grid;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}

/* Custom scroll bar css starts */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Corner where both X and Y scroll bar meets */
::-webkit-scrollbar-corner {
  background: none;
}
/* Custom scroll bar css ends */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 1.5px 11px;
}
.primary_button_style {
  background-color: var(--primary);
  color: white;
}
:where(.css-dev-only-do-not-override-1iacbu2).ant-notification
  .ant-notification-notice
  .ant-notification-notice-close {
  color: var(--close-icon);
}

:where(.css-dev-only-do-not-override-1iacbu2).ant-notification
  .ant-notification-notice
  .ant-notification-notice-close:hover {
  color: var(--close-icon);
}
.red {
  color: red !important;
}

.ant-spin-dot-item:nth-child(1) {
  background-color: rgb(12, 12, 224);
}
.ant-spin-dot-item:nth-child(2) {
  background-color: rgb(16, 241, 16);
}
.ant-spin-dot-item:nth-child(4) {
  background-color: rgb(255, 255, 28);
}
.ant-spin-dot-item:nth-child(3) {
  background-color: rgb(249, 5, 46);
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", sans-serif;
  --white: #ffffff;
  --black: #000000;

  --primary: #092c4c;
  --secondary: #0c3861;

  --font-color-1: #ffffff;

  /* font sizes */
  --font-size-16: 16px;
  --font-size-14: 14px;

  /* font weights */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;

  --page-bg-1: #ffffff;
  --page-bg-2: #ffffff;

  --border-color-1: #e1e1e1;
  --border-color-2: #efeaea;
  --border-color-3: #d9d9d9;
  --border-color-4: #0000000f;
  --hover-color: #d9d9d966;

  --toggle-icon-bg: #cad5dc;
  --chip-bg: #d2e6ff;
  --icons-color: #0958d9;
  --lineage-blue: #f2fbff;
  --lineage-yellow: #fef6e1;
  --project-card-bg: #e6f4ff;
  --project-title: #092c4c;
  --project-detail-bg: #ffffff;
  --segment-bg: #ebebeb;
  --sidebar: #f5f7f9;
  --check-icon: #e6f4ff;
  --circle-color: #00000073;
  --not-selected: #0000000f;
  --config-bg: "#ffffff";

  --panel-lable: #000000;
  --panel-lable-disabled: #6e6e6e;
  --project-box-shadow: 0px 1px 2px 0px #00000008;
  --project-hover-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 6px 20px rgba(0, 0, 0, 0.1);
  --island-bg: #e9ebee;
  --card-header-bg: #ffe8b0;
  --question-icon: #00000073;
  --hover-bg: rgb(216, 213, 213);
  --close-icon: rgba(0, 0, 0, 0.45);
}

.dark {
  --white: #000000;
  --black: #ffffff;

  --primary: #092c4c;
  --secondary: #0c3861;

  --font-color-1: #ffffff;

  --page-bg-1: #000000;
  --page-bg-2: #141414;

  --border-color-1: #1d1d1d;
  --border-color-2: #383535;
  --border-color-3: #424242;
  --border-color-4: #383838;
  --hover-color: #42424266;

  --toggle-icon-bg: #40484e;
  --chip-bg: #40484e;
  --icons-color: #fff;
  --lineage-blue: #205072;
  --lineage-yellow: #5c4412;
  --project-card-bg: #092c4c;
  --project-title: #ffffff;
  --project-detail-bg: #262626;
  --segment-bg: #333333;
  --sidebar: #1d1d1d;
  --check-icon: #111a2c;
  --circle-color: #ffffff;
  --not-selected: #ffffff73;
  --config-bg: #141414;

  --panel-lable: #ffffff;
  --panel-lable-disabled: #aaa;
  --project-box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.1);
  --project-hover-box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.15),
    0px 6px 20px rgba(255, 255, 255, 0.1);
  --island-bg: #424242;
  --card-header-bg: #8c6300;
  --question-icon: #ffffff;
  --hover-bg: rgb(109, 103, 103);
  --close-icon: rgba(255, 255, 255, 0.45);
}
